import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IonButton, IonIcon, IonTextarea } from "@ionic/react";
import {
  documentTextOutline,
  thumbsUpOutline,
  thumbsDownOutline,
} from "ionicons/icons";
import { saveResponsePartial } from "../localInspectionSlice";
import PromptWrapper from "./PromptWrapper";
import "./Updown.css";

const type = "updown";

const Updown = ({ prompt }) => {
  const dispatch = useDispatch();
  const [showNote, setShowNote] = useState(false);
  const path = prompt.path;
  const response = prompt.response;

  const onResponse = (value) => {
    dispatch(
      saveResponsePartial({
        path,
        type,
        key: "value",
        value,
      })
    );
  };

  const onClickUp = () => onResponse(true);

  const onClickDown = () => {
    onResponse(false);
    if (!response.note) {
      setShowNote(true);
    }
  };

  const colorUp = response.value === true ? "success" : "medium";
  const colorDown = response.value === false ? "danger" : "medium";

  const toggleNote = () => setShowNote(!showNote);

  const setNoteText = (e) => {
    const value = e.detail.value;
    dispatch(
      saveResponsePartial({
        path,
        type,
        key: "note",
        value,
      })
    );
  };

  const noteStateClasses = () => {
    const classes = [];
    if (response.value === false && !response.note) {
      classes.push("prompt-type-updown__note-required");
    }

    if (response.note) {
      classes.push("prompt-type-updown__note-present");
    }

    return classes.join(" ");
  };

  return (
    <PromptWrapper type="updown">
      <div className={`prompt-type-updown ${noteStateClasses()}`}>
        <IonButton
          onClick={onClickUp}
          fill="clear"
          color={colorUp}
          className="prompt-type-updown__icon"
        >
          <IonIcon icon={thumbsUpOutline} slot="icon-only" size="small" />
        </IonButton>
        <IonButton
          onClick={onClickDown}
          fill="clear"
          color={colorDown}
          className="prompt-type-updown__icon"
        >
          <IonIcon icon={thumbsDownOutline} slot="icon-only" size="small" />
        </IonButton>
        <div className="prompt-type-updown__prompt-area">
          <div className="prompt-type-updown__prompt-label">
            {prompt.title}{" "}
            {prompt.optional ? null : <span className="required">*</span>}
          </div>
          {showNote && (
            <div className="prompt-type-updown__prompt-note">
              <IonTextarea
                rows={2}
                placeholder={
                  response.value === false
                    ? "Enter deficiency notes..."
                    : "Enter optional notes..."
                }
                value={response.note}
                onIonChange={setNoteText}
              />
            </div>
          )}
        </div>
        <IonButton
          onClick={toggleNote}
          fill="clear"
          color="medium"
          className="prompt-type-updown__icon prompt-type-updown__note-icon"
        >
          <IonIcon icon={documentTextOutline} slot="icon-only" size="small" />
        </IonButton>
      </div>
    </PromptWrapper>
  );
};

export default Updown;
